/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Arabian Yachting's Commitment to Sustainability"), "\n", React.createElement(_components.p, null, "Arabian Yachting is committed to sustainability and has implemented various eco-friendly initiatives. The company is actively exploring alternative fuels, waste reduction strategies, and carbon offset programs. They are dedicated to minimizing their environmental impact and promoting sustainable practices in the yachting industry. Arabian Yachting also invites its esteemed clients to join in these green efforts through educational campaigns and participatory conservation activities."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Source: ", React.createElement(_components.a, {
    href: "https://markets.businessinsider.com/news/stocks/arabian-yachtings-commitment-to-sustainability-ecofriendly-initiatives-unveiled-1033165332"
  }, "Arabian Yachting's Commitment to Sustainability: Eco-Friendly Initiatives Unveiled"))), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Please note that I couldn't find specific details about Arabian Yachting's sustainability initiatives on the provided website. However, the information mentioned above is based on the general commitment to sustainability mentioned in the article.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
